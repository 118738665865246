import React, { useState } from 'react';
import Stereo from '../images/stereo.png'; 
import Logo from '../images/logo/Quick-Care.png';
import { Link, useNavigate } from 'react-router-dom';
import { setToken, setUsername } from '../utils/tokenHelper';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handlePhoneNumberChange = (e) => {
        const input = e.target.value;
        setPhoneNumber(input);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!phoneNumber || phoneNumber.length < 10) {
            setMessage('Phone number must be at least 10 digits long.');
            setIsError(true);
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ phone_number: phoneNumber })
        };
    
        try {
            setIsLoading(true);
            const response = await fetch('https://api.quickcare.ng/api/v1/user/passwordless', requestOptions);

            if (response.ok) {
                const result = await response.json();
                setToken(result.access_token);
                setUsername(phoneNumber);
                console.log(`Saved username: ${phoneNumber}`);
                setMessage('Login successful!');
                setIsError(false);
                setTimeout(() => {
                    navigate('/health');
                }, 2000);
            } else {
                const errorResponse = await response.json();
                if (errorResponse.detail && Array.isArray(errorResponse.detail)) {
                    const errorMessages = errorResponse.detail.map((err) => err.msg).join(', ');
                    setMessage(`Login failed: ${errorMessages}`);
                } else {
                    setMessage('Login failed: Invalid phone number.');
                }
                setIsError(true);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='w-full md:flex flex-row h-screen'>
            <div className='w-full flex flex-col gap-4 p-7 md:w-1/2 md:items-start md:justify-center md:gap-16 lg:gap-8'>
                <Link to='/'>
                    <img src={Logo} alt="" className='w-[150px] h-auto' />
                </Link>

                <div className='mt-4 flex w-full flex-col gap-2'>
                    <h1 className='text-2xl font-semibold'>Login</h1>

                    <div className='flex flex-row gap-6'>
                        <p>New to QuickCare?</p>
                        <Link to='/signup' className='text-[#45B139]'>Create Account</Link>
                    </div>
                </div>

                {message && (
                    <div className={`mt-4 p-2 text-center ${isError ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </div>
                )}

                <form onSubmit={handleSubmit} className='flex flex-col w-full gap-4 items-center md:gap-8'>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Phone Number'
                        />
                    </div>

                    <div className='flex items-center w-full justify-between mt-2'>
                        <div className='flex items-center gap-2'>
                            <input type="checkbox" className='w-5 h-5' />
                            <p className='text-sm'>Remember me</p>
                        </div>
                    </div>

                    <button 
                        type='submit' 
                        className='w-full flex items-center justify-center p-2 rounded bg-[#069125] font-medium text-white tracking-wider'
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="loader">
                                Loading ....
                            </div>
                        ) : (
                            'Login'
                        )}
                    </button>
                </form>
            </div>

            <img src={Stereo} alt="" className='hidden md:flex w-1/2' />
        </div>
    );
};

export default Login;
